import React from 'react'
// import image_vero from '../../resources/images/retrato.png'

import './styles/container.css'

const Container = ({children}) => {
  return (
    <div className='container'>
        {children}
        {/* <img src={image_vero} alt="" /> */}
        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat provident, ipsum doloremque saepe, nulla quos possimus distinctio alias deleniti facilis aliquid minima, enim aut. Sequi aperiam quidem magni ea ipsam!
        Sapiente ratione a ducimus porro dignissimos quos adipisci commodi repellendus accusamus architecto? Culpa vel iure ea cumque, voluptatem nemo enim fuga recusandae unde molestias accusamus molestiae amet hic, eius non.
        Eveniet quibusdam reiciendis facere placeat cumque laboriosam perspiciatis non, consectetur deleniti temporibus eos dolor? Esse ducimus accusantium a sunt, quaerat facere nostrum voluptas. Enim debitis illum sed cupiditate voluptatibus repellendus!
        Expedita itaque magnam quo aliquam perspiciatis ad, odio nihil ratione cumque maiores, repellendus unde ab eligendi, distinctio id doloremque incidunt minima. Distinctio, eligendi! Dolore voluptates cum doloremque dicta repudiandae autem?
        Enim molestiae tenetur ratione ipsam veniam magnam! Optio officiis nisi assumenda quos aperiam ullam numquam quia laudantium sint magnam. Ratione dolores totam rem labore consequuntur error tempora placeat, illo quaerat.</p> */}
    </div>
  )
}

export default Container