import React from 'react'
import composition from '../../resources/images/Floral Composition 4.png'
import Slider from '../Pages/Slider'
import './styles/home.css'

const Home = () => {
  const quotes = [
    " ...the creative process must respond to a previous study... to break the structures it's necessary to know them...",
    "...the artist is like a Magician...",
    "...the artist mixes pigments and creates his own colors...",
    "...the artist is at the same time an Alchemist..."
  ]

  return (
    <>
      <div className='home'>
        <img src={composition} alt="principal painting" />
        <Slider items = {quotes}/>
        {/* <div className='frases'>
          <div>"...El proceso creativo debe responder a un estudio previo... para <span>romper las estructuras</span> hace falta conocerlas..."</div>
          <div>"...El artista es como un <span>Mago</span>..."</div>
          <div>"...El artista es al mismo tiempo <span>un Alquimista</span>..."</div>
          <div>"...Los griegos decían que la alquimia consiste en mezclar fluidos y <span>crear algo nuevo</span>"</div>
          <div>"...el artista mezcla pigmentos y crea <span>sus propios colores</span>"</div>
        </div> */}
      </div>
    </>
  )
}

export default Home