import React from 'react'
import instagram_logo from '../../resources/logos/instagram-logo.svg'
import './styles/footer.css'


const Footer = () => {
  return (
    <footer>
        <ul className='no-bullets left'>
            <li><span>Verónica Ferraro</span></li>
        </ul>
        {/* <ul className='no-bullets right'> */}
            {/* <li><img src={instagram_logo} alt="" /></li> */}
        {/* </ul> */}
        <div className='parent-p'><p>©MaruFerraro</p></div>
    </footer>
  )
}

export default Footer