export const flowersPaintings = [
    {
        id: 1,
        title: 'Angelina, el Nacimiento de la Primavera',
        url: '/paintings/flowers/ANGELINA, EL NACIMIENTO DE LA PRIMAVERA   1.jpg',
    },
    {
        id: 2,
        title: 'California Flowers II',
        url: '/paintings/flowers/CALIFORNIA FLOWERS II   2.jpg',
    },
    {
        id: 3,
        title: 'California Flowers I',
        url: '/paintings/flowers/CALIFORNIA FLOWERS I    3.jpg',
    },
    {
        id: 4,
        title: 'Sinfonia de Girasoles II',
        url: '/paintings/flowers/SINFONIA DE GIRASOLES II   4.jpg',
    },
    {
        id: 5,
        title: 'Irises',
        url: '/paintings/flowers/Irises   5.jpg',
    },
    {
        id: 6,
        title: 'Floral I',
        url: '/paintings/flowers/Floral1   6.jpg',
    },
    {
        id: 7,
        title: 'Romance de Complementarios',
        url: '/paintings/flowers/ROMANCE DE COMPLEMENTARIOS  7.jpg',
    },
    {
        id: 8,
        title: 'Amarillo Vibrante',
        url: '/paintings/flowers/AMARILLO VIBRANTE   8.jpg',
    },
    {
        id: 9,
        title: 'Iris',
        url: '/paintings/flowers/Iris   9.jpg',
    },
    {
        id: 10,
        title: 'Flower',
        url: '/paintings/flowers/Flower   10.jpg',
    },
    {
        id: 11,
        title: 'Autum',
        url: '/paintings/flowers/Autum  11.jpg',
    },
    {
        id: 12,
        title: 'Composicion Floral I',
        url: '/paintings/flowers/Composicion Floral  12.jpg',
    },
    {
        id: 13,
        title: 'Memorias de Costa Rica',
        url: '/paintings/flowers/MEMORIAS DE COSTA RICA   13.jpg',
    },
    {
        id: 14,
        title: 'Primavera',
        url: '/paintings/flowers/PRIMAVERA   14.jpg',
    },
    {
        id: 15,
        title: 'Juntos',
        url: '/paintings/flowers/juntos  15.jpg',
    },
    {
        id: 16,
        title: 'Composicion Floral III',
        url: '/paintings/flowers/composicion floral3   16.jpg',
    },
    {
        id: 17,
        title: 'Complementarios',
        url: '/paintings/flowers/complementarios 17.jpg',
    },
    {
        id: 18,
        title: 'Floral II',
        url: '/paintings/flowers/Floral2   18.jpg',
    },
    {
        id: 19,
        title: 'Sinfonia de Girasoles',
        url: '/paintings/flowers/Sinfonia de girasoles   19.jpg',
    },
    {
        id: 20,
        title: 'Amarillo',
        url: '/paintings/flowers/Amarillo  20.jpg',
    },
    {
        id: 21,
        title: 'Blossoming',
        url: '/paintings/flowers/Blossoming 21.jpg',
    },
    {
        id: 22,
        title: 'Bellagio Flowers',
        url: '/paintings/flowers/BELLAGIO FLOWERS  22.jpg',
    },
    {
        id: 23,
        title: 'Casi una Docena',
        url: '/paintings/flowers/CASI UNA DOCENA  23.jpg',
    },
    {
        id: 24,
        title: 'Emociones',
        url: '/paintings/flowers/Emciones  24.jpg',
    },
    {
        id: 25,
        title: 'Composicion Floral II',
        url: '/paintings/flowers/COMPOSICION FLORAL 2  25.jpg',
    },
    {
        id: 26,
        title: 'Jardin de colores',
        url: '/paintings/flowers/Jardin de colores 26.jpg',
    },
]




export const nudesPaintings = [
    {
        id: 1,
        title: 'Angi',
        url: '/paintings/nudes/Angi  1.jpg',
    },
    {
        id: 2,
        title: 'Distant',
        url: '/paintings/nudes/Distant  2.jpg',
    },
    {
        id: 3,
        title: 'Ensueño',
        url: '/paintings/nudes/Ensueno  3.jpg',
    },
    {
        id: 4,
        title: 'Recostada',
        url: '/paintings/nudes/recostada     4.jpg',
    },
    {
        id: 5,
        title: 'Yellow Dress',
        url: '/paintings/nudes/yellow dress   5.jpg',
    },
    {
        id: 6,
        title: 'Dormida I',
        url: '/paintings/nudes/dormida    6.jpg',
    },
    {
        id: 7,
        title: 'Curvas y Abismos',
        url: '/paintings/nudes/Curvas y Abismos   7.jpg',
    },
    {
        id: 8,
        title: 'Mercy',
        url: '/paintings/nudes/Mercy  8.jpg',
    },
    {
        id: 9,
        title: 'Saint Grial',
        url: '/paintings/nudes/saint grial   9.jpg',
    },
    {
        id: 10,
        title: 'En Busca de la Pureza Original',
        url: '/paintings/nudes/en busca de la pureza original   10.jpg',
    },
    {
        id: 11,
        title: 'Querubin',
        url: '/paintings/nudes/Querubin  11.jpg',
    },
    {
        id: 12,
        title: 'Florecer',
        url: '/paintings/nudes/Florecer 12.jpg',
    },
    {
        id: 13,
        title: 'Llanto Desesperado',
        url: '/paintings/nudes/Llanto desesperado  13.jpg',
    },
    {
        id: 14,
        title: 'Ondina',
        url: '/paintings/nudes/Ondina   14.jpg',
    },
    {
        id: 15,
        title: 'Dormida II',
        url: '/paintings/nudes/Dormida   15.jpg',
    },
    {
        id: 16,
        title: 'Sobre un Manto Floreado',
        url: '/paintings/nudes/Sobre un manto floreado    16.jpg',
    },
    {
        id: 17,
        title: 'Hubo un Tiempo en que no Habia Tiempos',
        url: '/paintings/nudes/Hubo un tiempo en que no habia tiempos   17.jpg',
    },
    {
        id: 18,
        title: 'Descanso Primaveral',
        url: '/paintings/nudes/descanso primaveral   18.jpg',
    },
]


export const landscapesPaintings = [
    {
        id: 1,
        title: 'Trigal',
        url: '/paintings/landscapes/Trigal   1.jpg',
    },
    {
        id: 2,
        title: 'Sunset in the Desert',
        url: '/paintings/landscapes/Sunset in the desert   2.jpg',
    },
    {
        id: 3,
        title: 'Storm in the Desert',
        url: '/paintings/landscapes/Storm in the desert   3.jpg',
    },
    // {
    //     id: 4,
    //     title: 'Recostada',
    //     url: '/paintings/landscapes/recostada     4.jpg',
    // },
    // {
    //     id: 5,
    //     title: 'Yellow Dress',
    //     url: '/paintings/landscapes/yellow dress   5.jpg',
    // },
    {
        id: 6,
        title: 'Azul Inquieto',
        url: '/paintings/landscapes/AZUL INQUIETO  6.jpg',
    },
    {
        id: 7,
        title: 'Golden Fields',
        url: '/paintings/landscapes/Golden Fields  7.jpg',
    },
    {
        id: 8,
        title: 'Horizontes Argentinos II',
        url: '/paintings/landscapes/HORIZONTES ARGENTINOS II   8.jpg',
    },
    {
        id: 9,
        title: 'Jaco Beach',
        url: '/paintings/landscapes/JACO BEACH   9.jpg',
    },
    {
        id: 10,
        title: 'Sola en la Playa Hermosa',
        url: '/paintings/landscapes/SOLA EN PLAYA HERMOSA  10.jpg',
    },
    // {
    //     id: 11,
    //     title: 'Querubin',
    //     url: '/paintings/landscapes/Querubin  11.jpg',
    // },
    {
        id: 12,
        title: 'Tiempo de Cosecha',
        url: '/paintings/landscapes/TIEMPO DE COSECHA  12.jpg',
    },
    // {
    //     id: 13,
    //     title: 'Llanto Desesperado',
    //     url: '/paintings/landscapes/Llanto desesperado  13.jpg',
    // },
    // {
    //     id: 14,
    //     title: 'Ondina',
    //     url: '/paintings/landscapes/Ondina   14',
    // },
    {
        id: 15,
        title: 'Metropolis',
        url: '/paintings/landscapes/METROPOLIS   15.jpg',
    },
    {
        id: 16,
        title: 'El Encanto de la Calle Cristo',
        url: '/paintings/landscapes/el encanto de la calle cristo   16.jpg',
    },
    {
        id: 17,
        title: 'The Colour of My Soul',
        url: '/paintings/landscapes/the colour of my soul   17.jpg',
    },
    {
        id: 18,
        title: 'Esperando el Temporal',
        url: '/paintings/landscapes/esperando el temporal   18.jpg',
    },
    {
        id: 19,
        title: 'Plumerillos',
        url: '/paintings/landscapes/Plumerillos   19.jpg',
    },
    {
        id: 20,
        title: 'Panga en Playa del Coco',
        url: '/paintings/landscapes/PANGA EN PLAYA DEL COCO   20.jpg',
    },
    {
        id: 21,
        title: 'Pampa Mia I',
        url: '/paintings/landscapes/Pampa Mia I  21.jpg',
    },
    {
        id: 22,
        title: 'Pampa Mia II',
        url: '/paintings/landscapes/Pampa Mia II   22.jpg',
    },
    {
        id: 23,
        title: 'Maduro para la Cosecha',
        url: '/paintings/landscapes/Maduro para la cosecha    23.jpg',
    },
    {
        id: 24,
        title: 'Tranquera en Flor',
        url: '/paintings/landscapes/Tranquera en flor   24.jpg',
    },
    {
        id: 25,
        title: 'Tarde de verano',
        url: '/paintings/landscapes/Tarde de verano   25.jpg',
    },
    {
        id: 26,
        title: 'Horizontes Argentinos',
        url: '/paintings/landscapes/Horizontes Argentinos   26.jpg',
    },
    // {
    //     id: 27,
    //     title: 'Esperando el Temporal',
    //     url: '/paintings/landscapes/esperando el temporal   18.jpg',
    // },
    // {
    //     id: 28,
    //     title: 'Esperando el Temporal',
    //     url: '/paintings/landscapes/esperando el temporal   18.jpg',
    // },
    {
        id: 29,
        title: 'destellos Placenteros',
        url: '/paintings/landscapes/destellos placenteros   29.jpg',
    },
    {
        id: 30,
        title: 'Nevada, The Silver State',
        url: '/paintings/landscapes/NEVADA, THE SILVER STATE   30.jpg',
    },
    {
        id: 31,
        title: 'Urban I',
        url: '/paintings/landscapes/Urban I   31.jpg',
    },
    {
        id: 32,
        title: 'Rainy Season',
        url: '/paintings/landscapes/RAINY SEASON   32.jpg',
    },
    {
        id: 33,
        title: 'Paraiso Fecundo',
        url: '/paintings/landscapes/Paraiso fecundo  33.jpg',
    },
    {
        id: 34,
        title: 'Silencio Mistico',
        url: '/paintings/landscapes/Silencio Mistico    34.jpg',
    }
]




export const deadNaturePaintings = [
    {
        id: 1,
        title: 'Bodegon Argentino',
        url: '/paintings/dead_nature/bodegon argentino    1.jpg',
    },
    {
        id: 2,
        title: 'Sabores Culinarios',
        url: '/paintings/dead_nature/Sabores Culinarios  2.jpg',
    },
    {
        id: 3,
        title: 'Recuerdos del Caribe',
        url: '/paintings/dead_nature/Recuerdos del Caribe    3.jpg',
    },
    {
        id: 4,
        title: 'Frutos de la Tierra Dulce',
        url: '/paintings/dead_nature/Frutos de la tierra dulce    4.jpg',
    },
    {
        id: 5,
        title: 'Ofrendas del Paraíso',
        url: '/paintings/dead_nature/Ofrendas del paraiso   5.jpg',
    },
    {
        id: 6,
        title: 'La Sangre de Lares',
        url: '/paintings/dead_nature/La Sangre de Lares   6.jpg',
    },
    {
        id: 7,
        title: 'Limones',
        url: '/paintings/dead_nature/Limones   7.jpg',
    },
    {
        id: 8,
        title: 'Las Tres Gracias',
        url: '/paintings/dead_nature/Las tres gracias  8.jpg',
    },
    {
        id: 9,
        title: 'Sinfonía Tropical',
        url: '/paintings/dead_nature/Sinfonia Tropical   9.jpg',
    },
    {
        id: 10,
        title: 'Mandarinas',
        url: '/paintings/dead_nature/Mandarinas  10.jpg',
    },
    {
        id: 11,
        title: 'Morterito',
        url: '/paintings/dead_nature/Morterito  11.jpg',
    }
]

// export default [ flowersPaintings , nudesPaintings ];