import {React, useState, useEffect} from 'react'
import './styles/gallery.css'


const Gallery = ({images}) => { 
    const [value, setValue] = useState(images);
    
    useEffect(() => { setValue(images) }, [images]);
  
    return (
    <>
        { images.map( item => 
        <div key={item.id+'c'} className='card'>
            <div key={item.id+'ct'} className='card-title'>{item.title}</div>
            <div key={item.id+'cb'} className='card-body'>
                <img key={item.id+'i'} src={item.url} alt="" />
            </div>
        </div>)
        }
    </>
  )
}

export default Gallery