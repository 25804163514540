import { React , useEffect } from 'react'
import { Routes, Route , useNavigate} from "react-router-dom"; 
import Layout from './components/Layout/Layout'
import Home from './components/Pages/Home'
import About from './components/Pages/About';
import Paintings from './components/Pages/Paintings';
import Contact from './components/Pages/Contact';


const App = () => {
  const navigate = useNavigate()
  useEffect(() => {
    // window.onbeforeunload = () => {
    //   console.log('hola')
      navigate('/')
      //window.sessionStorage.setItem('lastRoute', JSON.stringify(window.location.pathname))
  //}
},  []);

  return (
    <>
        <Layout>
            <Routes forceRefresh={true}>
                <Route path="/" element={<Home/>} />
                <Route path="/about" element={<About />} />
                <Route path="/paintings" element={<Paintings />} />
                <Route path="/contact" element={<Contact />} />
                {/* <Layout/> */}
            </Routes>
        </Layout>
    </>
  )
}

export default App