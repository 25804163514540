import {React, useState } from 'react'
import { Link } from "react-router-dom";
import logo from '../../resources/logos/logo-with-flower.png'
import menu_open from '../../resources/utils/menu-open.png'
import menu_close from '../../resources/utils/menu-close.png'
import './styles/header.css'


const Header = () => {
  let [showMenu, setShowMenu] = useState(false);

  const clickHandler = () => {
    setShowMenu(!showMenu);
    // console.log(showMenu)
  };


  return (
    <header>
        <Link to="/"><div className='logo'><img src={logo} alt="" /></div></Link>
        <div>
        <div className='menu-icon' onClick={clickHandler}><img src={showMenu ? menu_close : menu_open} alt="" /></div>
        <div className={showMenu ? 'active' : 'inactive'}>
          <div className='menu-body'>            
            <ul className='no-bullets'>
              <Link to="/about" className='link' onClick={clickHandler}><li className='first'><span className='menu-items'>About Me</span></li></Link>
              <Link to="/paintings" className='link' onClick={clickHandler}><li className='second'><span className='menu-items'>My Paintings</span></li></Link>
              <Link to="/contact" className='link' onClick={clickHandler}><li className='third'><span className='menu-items'>Contact Me</span></li></Link>
            </ul>
          </div>
        </div>
        </div>
    </header>
  )
}

export default Header