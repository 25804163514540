import {React /*, useEffect*/ } from 'react'
// import { useNavigate } from "react-router-dom";

import logo from '../../resources/logos/firma.png'
// import flower from '../../resources/images/flower-reflected.png'
import stroke from '../../resources/images/guarda_nitida.png'
import './styles/opening.css'


const Opening = () => {

  // const navigate = useNavigate()

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     navigate('/home', { replace: true });
  //   }, 10000);

  //   return () => clearTimeout(timeout);
  // }, []);

  return (
    <main>
      <img className='logo' src={logo} alt="" />
      {/* <img className='flower' src={flower} alt="" /> */}
      <div className='box'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <img className='stroke' src={stroke} alt="" />
    </main>
  )
}

export default Opening