import { React , useState, useRef } from 'react'
import { flowersPaintings , nudesPaintings, landscapesPaintings, deadNaturePaintings} from '../../resources/utils/paintings.js'
import Carousel from "./Carousel";
import Gallery from './Gallery.jsx';

import './styles/paintings.css'

const Paintings = () => {

  let [imagesSelected, setImagesSelected] = useState(flowersPaintings);
  const ref = useRef(null);

  const clickHandler = (event,data) => {
    ref.current?.scrollIntoView({behavior: 'auto'});
    console.log(data)
    if (data==='flowers') setImagesSelected(flowersPaintings)
    else if (data==='nudes') setImagesSelected(nudesPaintings)
    else if (data==='landscapes') setImagesSelected(landscapesPaintings)
    else setImagesSelected(deadNaturePaintings)
    
  }



  return (
    <div className='paintings'>
      <div className='paintings-options'>
        <ul>
          <li key="1" onClick={(event) => clickHandler(event, 'flowers')} >Flowers</li>
          <li key="2" onClick={(event) => clickHandler(event, 'nudes')} >Nudes</li>
          <li key="3" onClick={(event) => clickHandler(event, 'landscapes')} >Landscapes</li>
          <li key="4" onClick={(event) => clickHandler(event, 'deadNature')} >Dead Nature</li>
        </ul>
      </div>
      <div className='paintings-content'>
        <div className='paintings-carousel'>
          <Carousel images={imagesSelected}/>
        </div>
        <div ref={ref} className='paintings-gallery' >
          <Gallery images={imagesSelected} />
           {/* <Carousel images={imagesSelected}/> */}
        </div>    
      </div>
    </div>
  )
}

export default Paintings