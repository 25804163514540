import { React ,useState, useEffect } from 'react'
import Opening from '../Opening/Opening'
import Header from './Header'
import Container from './Container'
import Footer from './Footer' 
import './styles/layout.css'


const Layout = ({children}) => {

  // const navigate = useNavigate()
  let [displayOpening, setDisplayOpening] = useState(true);
  let [displayLayout, setDisplayLayout] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // navigate('/home', { replace: true });
      setDisplayLayout(true);
      setDisplayOpening(false);
      console.log(displayOpening);
    }, 10000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
        <div className= {displayOpening ? 'active-block' : 'inactive-block'} >
          <Opening/>
        </div>
        <div className= {displayLayout ? 'active-block' : 'inactive-block'}>
          <div className='header-index'>
            <Header/>
          </div>
          <div className= 'container-index'>
            <Container children={children}/>
          </div>
          <Footer/>
        </div>
    </>
  )
}

export default Layout