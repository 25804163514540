import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import flower from '../../resources/images/flower-reflected.png'
import './styles/contact.css'

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_89h6k5y', 'template_4s58jtq', form.current, 'TN69SWjXWvYuzt0Cx')
      .then((result) => {
          console.log(result.text);
          form.current.reset()
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail}  className='contact-form'>
      <div className='wp'><a href="https://wa.me/17029317367">lala</a></div>
      <div>
        <label>What your Name?</label>
        <input type="text" name="user_name" />
      </div>

      <div>
        <label>And your Email?</label>
        <input type="email" name="user_email" />
      </div>

      <div>
        <label>Tell me your Message</label>
        <textarea name="message" />
      </div>
      
      <div>
        <input type="submit" value="Send" />   
      </div>
      
    </form>
  );
};





  // return (
  //  <div className='contact'>

    

    {/* <form className='contact-email'  action="https://formsubmit.co/ferraro.mariaeugenia@gmail.com" method="POST" >
      <label htmlFor="first-name">First Name</label>
      <input type="text" name='first-name'/>
      <label htmlFor="last-name">Last Name</label>
      <input type="text" name='last-name'/>
      <label htmlFor="message">Message</label>
      <textarea name='first-name'/>
    </form> */}
    {/* <img className='contact-img' src={flower} alt="" /> */}
    {/* <div className='contact-message'>Under Construction</div> */}
    {/* <div className='wp'> <a href={URL}>Whatsapp</a></div>
  </div>
  )
} */}



export default Contact