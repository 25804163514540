import { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";
import './styles/carousel.css'


const Carousel = ({images}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [prevIndex, setPrevIndex] = useState(0);
    // const [nextIndex, setNextIndex] = useState(0);
    const [value, setValue] = useState(images);
    
    useEffect(() => {
        console.log('aca estoy')
        setValue(images) 
        setCurrentIndex(0)
        // setPrevIndex(0)
    }, [images]);



    const handleNext = () => {
      try {
        setCurrentIndex((prevIndex) => prevIndex + 1 === images.length ? 0 : prevIndex + 1);
      } catch {
        setCurrentIndex(0);
      }   
    };

    const handlePrevious = () => {
        try {
            setCurrentIndex((prevIndex) => prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1);
        } catch{
            setCurrentIndex(0);
        }
      
    };

    const handleDotClick = (index) => {
      setCurrentIndex(index);
    };

    // const handleNext = () => {
    //     console.log(currentIndex)
    //     console.log(prevIndex)
    //     setPrevIndex(prevIndex + 1 === images.length ? 0 : prevIndex + 1 )
    //     setCurrentIndex(prevIndex)
    //     console.log(prevIndex)
    // };

    // const handlePrevious = () => {
    //     setPrevIndex(prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1 )
    //     setCurrentIndex(prevIndex);
    // };

    // const handleDotClick = (index) => {
    //   setCurrentIndex(index);
    //   console.log(currentIndex)
    // };


  return (
    currentIndex > images.length ? setCurrentIndex(0) :
    <div className="carousel">
        <h2>{images[currentIndex].title}</h2>
         <img className="carousel-images " key={currentIndex} src={images[currentIndex].url}/>
        <div className="slide_direction">
            <div className="left-arrow" onClick={handlePrevious}></div>
            <div className="right-arrow" onClick={handleNext}></div>
        </div>
    </div>
  )
}

export default Carousel