import React from 'react'
import image_vero from '../../resources/images/retrato.jpg'

import './styles/about.css'

const About = () => {
  return (
    <div className='about'>
        <img src={image_vero} alt="" />
        <p>Verónica Ferraro was born in Cañada de Gómez, Santa Fe, Argentina. She moved to the US where she currently lives to this day.<br />
          Ferraro treasures a solid academic formation, spent in institutions in Argentina, the U.S. and Europe. Among others we mention: the National University of Humanities and Arts in Argentina; a Bachelor of Arts with a concentration in Painting; the Corcoran School of Art and Design in Washington DC: The Miami International University of Art and Design; the University of Nevada in Las Vegas. She has also taken courses in significant workshops, such as: the Charles Scaglione Workshop in Buenos Aires, Argentina and the José Ludueña Workshop in Madrid, Spain.<br />
          Her Art have interacted with diverse audiences. In the South of the continent, they have come to the Arte Rosario Gallery in Argentina where the artist has worked on consignment since 2009, the Leonardo Fanuelle Art Gallery, the Network Foundation, the Cultural Passport Program in Buenos Aires and the Rivadavia Library in Cañada de Gómez, Argentina; to the North, they have been presented in the Latino Art Museum. They have also been shown in prestigious Puerto Rican galleries, such as: the A. Cueto Gallery, Space 304 and in the Tamara Gallery, etc.<br />
          Flowers, landscapes, and the human body are subjects of great interest and focus to her. Her recent work tries to capture the nature beauty found throughout the world by means of vibrant and contrasting colors, and a symphony of lines, shapes and textures.<br /> 
          Ferraro paints respecting the secrecy of the act and of the paint. She paints as an intimist, forcing her everyday world to take on an aesthetic dimension in the painting. She paints pictures that are pages of ecstasy. Paintings-metamorphosis, without attaching herself to the chimera of a style. Pictures that are quiet and full… She paints to go and seek for the things and beings that surround her, to allow through this exposure, their encounter.
        </p>
    </div>
  )
}

export default About
